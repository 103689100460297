<template>
  <div>
    <h2>支払い</h2>
    <div id="card-element"></div>
    <div id="add-new-card"></div>
  </div>
</template>

<script>
</script>
